import styled from "styled-components";
import React from "react";

const B = styled.button`
  padding: 0.8rem 1.6rem;
  border-radius: 8px;
  background-color: var(--color-accent-prim);
  color: white;
  border: none;
  text-transform: uppercase;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  text-align: center;
  border: solid 1px transparent;
  cursor: pointer;
  &.secondary {
    background-color: transparent;
  }
  &:hover {
    /*background-color: transparent;
    border: solid 1px var(--color-accent-prim);*/
    opacity: 0.7;
    transition: all 0.25s ease-in;
  }
  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
const A = styled.a``;

export default function Button(props) {
  const { label, url, onClick, type, buttonType, disabled } = props;
  if (url) {
    return (
      <a href={url}>
        <B {...props}>{label}</B>
      </a>
    );
  } else {
    return (
      <B className={`${type} ${disabled ? "disabled" : ""}`} onClick={() => onClick()} {...props} type={buttonType || "button"} disabled={disabled}>
        {label}
      </B>
    );
  }
}

Button.defaultProps = {
  label: "button",
  onClick: () => console.log("click"),
  type: "primary",
};
