import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useAccount } from "@azure/msal-react";
import styled from "styled-components";
import PageHeaderSub from "../components/PageHeaderSub";
import Page from "../components/UI/Elements/Page";
import Button from "components/UI/Buttons/Button";
import { getAxiosClient } from "api/axios";
import Loader from "components/UI/Loader";
import ios from "../assets/ios.svg";
import android from "../assets/android.png";

const Container = styled.div`
  text-align: center;
  color: white;
  min-height: 100vh;
  line-height: 2rem;
`;

const Header = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
`;

const SubHeader = styled.h2`
  font-size: 1.75rem;
  color: #b3b3b3;
`;

const MessageBox = styled.div`
  margin: 2rem auto;
  padding: 1rem 1rem;
  border-radius: 8px;
  max-width: 600px;
  background-color: ${(props) => (props.error ? "#c62828" : "#2E622B")};
  color: white;
`;

const LinkSection = styled.div`
  margin-top: 2rem;
  & a {
    color: var(--color-accent-prim);
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }

  & b{
      font-weight: 600;
    }

  & .appstore {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.2rem;
    gap: 2.4rem;
    & a{
      width: 30%;
      & .android{
        border-radius: 8px;
        border: 1px solid rgba(255,255,255,.6)
      }
    }
  }
`;

const Registration = () => {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const { code } = useParams();
  const { accounts } = useMsal();

  const account = useAccount(accounts?.[0] || {});
  const { given_name, family_name } = account?.idTokenClaims;

  const registerWithCode = async () => {
    const axiosClient = await getAxiosClient();
    try {
      return await axiosClient
        .get(`/loader/invite/${code}/investor`)
        .then(async (response) => {
          return await axiosClient
            .post(`/loader/invite/${code}/register`)
            .then((response) => {
              setLoading(false);
              return response.data;
            })
            .catch((error) => {
              setLoading(false);
              return Promise.reject(error);
            });
        })
        .catch((error) => {
          setLoading(false);
          return Promise.reject(error);
        });
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errorCode === "invite-already-completed") setErrorMessage("Invite already completed.");
      else setErrorMessage("❌ Oops! Something went wrong...");
    }
  };

  useEffect(() => {
    registerWithCode();
  }, []);

  return (
    <Page>
      <Container>
        <Header>Hey, {given_name}</Header>
        <PageHeaderSub />
        <br />
        {loading ? (
          <>
            <br />
            <SubHeader>Welcome to Finvictum!</SubHeader>
            <Loader />
          </>
        ) : errorMessage ? (
          <>
            <MessageBox error>
            <p>{errorMessage}</p>
            </MessageBox>
            <p>We're experiencing an issue. Don't worry.
            If you need immediate help, please contact our support team.</p>
            <br></br>
          <a href="mailto:support@finvictum.com"><Button label="Contact Support" /></a>
          </>
        ) : (
          <MessageBox>
            <p>You're officially part of something awesome!</p>
          </MessageBox>
        )}

        {!loading && !errorMessage && (
          <LinkSection>
             <p><b>Thanks for joining us — your journey starts now.</b></p>
             <br></br>
             <br></br>
            <p>👉 Explore the Finvictum Web App:</p>
            <p><a href="https://app.finvictum.com"> Access it right here</a></p>
            <br />
            <h2>OR</h2>
            <br />
            <p>Download our app for an elevated experience:</p>
            <p><a target="_blank" href="https://apps.apple.com/be/app/finvictum/id6444575602">iOS</a> or <a target="_blank" href="https://play.google.com/store/apps/details?id=com.finvictum">Android</a></p>
            <div class="appstore">
              <a target="_blank" href="https://apps.apple.com/be/app/finvictum/id6444575602"><img class="ios" src={ios} alt="iOS" /></a>
              <a target="_blank" href="https://play.google.com/store/apps/details?id=com.finvictum"><img class="android" src={android} alt="Android" /></a>
            </div>
          </LinkSection>
        )}
      </Container>
    </Page>
  );
};

export default Registration;
