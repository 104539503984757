import React from "react";
import styled from "styled-components";

const Body = styled.div`
  width: 95%;
  margin: 0 auto;
  max-width: 60rem;
  .page__header {
    margin-bottom: 2.4rem;
    & .page__header__sub {
      ul {
        display: flex;
        li {
          margin-left: 1rem;
        }
      }
    }
  }
`;

const Page = ({ children }) => {
  return <Body>{children}</Body>;
};

export default Page;
