import React from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Link, NavLink } from "react-router-dom";

import { useMsal } from "@azure/msal-react";
import { useAccount } from "@azure/msal-react";
import { UseOrganizationContext } from "../contexts";
import Logo from "../components/UI/Logo";
import CommunicationIcon from "../components/UI/Icons/Communication";
import DashboardIcon from "../components/UI/Icons/Dashboard";
import UsersIcon from "./UI/Icons/Users";
import EventsIcon from "../components/UI/Icons/Events";
import FollowersIcon from "../components/UI/Icons/Followers";
import InvestorInsightsIcon from "../components/UI/Icons/InvestorInsights";
import SupportIcon from "../components/UI/Icons/Support";
import LogoutIcon from "../components/UI/Icons/Logout";
import ProductsIcon from "../components/UI/Icons/Products";
import ProfileIcon from "../components/UI/Icons/Profile";
import ServiceIcon from "../components/UI/Icons/Service";

const Nav = styled.nav`
  position: relative;
  background-color: var(--color-black);
  width: 32rem;
  min-height: 100vh;
  display: none;
  .nav__inner {
    margin: 2rem 0;
    position: fixed;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 32rem;
    overflow-y: auto;
  }
  & .sub_menu {
    margin-left: 3.5rem;
  }
  .logo__container {
    margin-bottom: 2rem;
  }
  .username {
    text-align: center; 
    margin-right: 4rem;
    margin-top: 2rem;
    font-style:italic;
  }
  .logout-text {
    cursor: pointer;
  }
  ul {
    width: 100%;
    &.side_menu__secondary {
      align-self: flex-end;
      justify-self: flex-end;
      margin-bottom: 5rem;
      li {
        &:last-of-type {
          margin-top: 3.2rem;
        }
      }
    }
  }
`;

export default function Navigation() {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts?.[0] || {});

  const { given_name, family_name } = account?.idTokenClaims;

  const { Name } = UseOrganizationContext();
  console.log("UseOrganizationContext", UseOrganizationContext());

  const LogoutHandler = () => {
    try {
      // console.log("Trying to logout")
      const logoutResponse = instance.logout();
      console.log(logoutResponse);
    } catch (err) {
      console.log(err);
    }
  };
  
  return <></>

  return (
    <Nav>
      <div className="nav__inner">
        <div className="side_menu__primary">
          <div className="logo__container">
            <Logo />
            <NavLink style={{textTransform: "initial", color: "white"}} to="/profile">
              {Name && <><img style={{width: "48px", height: "auto", marginRight: "1.5rem"}} src="https://media.glassdoor.com/sqll/40930/euronav-n-v-squarelogo-1436162768151.png" /> {Name}</>}
            </NavLink>

          </div>
          <ul>
          </ul>
        </div>

        <ul className="side_menu__secondary">
          <li>
          <div className="username">
            Welcome {given_name}
          </div>
          <a className="logout-text" onClick={() => LogoutHandler()}>              
            <LogoutIcon />
            Log out
          </a>
          </li>
        </ul>
      </div>
    </Nav>
  );
}
